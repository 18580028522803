import React, { useState } from "react";

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import PaperChaseProgressBar from "../../components/PaperChaseProgressBar/PaperChaseProgressBar";
import PaperChaseResult from "../../components/PaperChaseResult/PaperChaseResult";
import PaperChaseStart from "../../components/PaperChaseStart/PaperChaseStart";
import PaperChaseStep from "../../components/PaperChaseStep/PaperChaseStep";
import { addUserAnswer, setCurrentStation, setPaperChase } from "../../features/PaperChaseSlice";
import PaperChaseLayout from "../layouts/PaperChaseLayout";
import Seo from "../Seo/Seo";

const PaperChaseStartPage = ({
  data: {
    paperChaseData,
  },
}) => {
  const {
    paperChase,
  } = useSelector((state) => state.paperChaseSlice);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const completedStations = useSelector((state) => state.paperChaseSlice.completedStations);
  const currentStation = useSelector((state) => state.paperChaseSlice.currentStation);
  const difficulty = useSelector((state) => state.paperChaseSlice.difficulty);
  const userAnswers = useSelector((state) => state.paperChaseSlice.userAnswers);

  dispatch(setPaperChase(paperChaseData));

  const [paperChaseStage, setPaperChaseStage] = useState("paperChaseStart");
  const [variant, setVariant] = useState("step1");

  // function handle send answer
  function handleSendAnswer() {
    if (currentStation === paperChase.paperChaseStation.length - 1) {
      setVariant("lastStep");
    } else {
      setVariant("evaluateStep2");
    }
  }

  function handleSkipStation() {
    dispatch(addUserAnswer({
      foundQRCode: false,
      index: currentStation,
      solvedQuiz: false,
    }));
    nextStation();
  }

  // function next station
  function nextStation() {
    dispatch(setCurrentStation(currentStation + 1));

    if (currentStation === paperChase.paperChaseStation.length - 1) {
      setPaperChaseStage("paperChaseResult");
    } else {
      setVariant("step1");
    }
  }

  if (paperChaseStage === "paperChaseStep") {
    const currentPaperChaseStation = paperChase.paperChaseStation[currentStation];
    const currentStationWithDifficulty = currentPaperChaseStation[difficulty];

    return (
      <PaperChaseLayout title={`${t("station")}} ${currentStation + 1}: ${t("title")}`} variantButton={t("leave")}>
        <PaperChaseProgressBar
          length={paperChase.paperChaseStation.length}
          activeIndex={currentStation}
        />
        <PaperChaseStep
          answer={[currentStationWithDifficulty.answer_a,
            currentStationWithDifficulty.answer_b,
            currentStationWithDifficulty.answer_c]}
          stationNumber={currentStation + 1}
          question={currentStationWithDifficulty.question}
          title="title"
          variant={variant}
          hint={currentPaperChaseStation.hint}
          image={currentPaperChaseStation.artwork._rawImages}
          rightAnswer={currentStationWithDifficulty[currentStationWithDifficulty.right_answer]}
          onNextStation={() => nextStation()}
          onSkip={() => handleSkipStation()}
          onScan={() => setVariant("step2")}
          onSendAnswer={() => handleSendAnswer()}
        />
      </PaperChaseLayout>
    );
  }

  if (paperChaseStage === "paperChaseResult") {
    return (
      <PaperChaseLayout title={t("yourResult")} variantButton="">
        <PaperChaseResult
          stations={userAnswers}
          title={paperChase.name}
          description={paperChase._rawDescription}
        />
      </PaperChaseLayout>
    );
  }

  return (
    <PaperChaseLayout title={t("introduction")}>
      <PaperChaseStart page={paperChase} onStart={() => setPaperChaseStage("paperChaseStep")} />
    </PaperChaseLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paperChaseData: sanityPaperChase(id: {eq: $id}) {
      id
      name
      _rawDescription
      slug {
        current
      }
      image {
        ...ImageWithPreview
        alt
      }
      Metadata {
        ...MetaData
      }
      paperChaseStation {
        hint
        id
        artwork {
          _rawImages
          title
        }
        paperChaseQuestionKids {
          answer_a
          answer_b
          answer_c
          question
          right_answer
        }
        paperChaseQuestionPro {
          answer_a
          answer_b
          answer_c
          question
          right_answer
        }
        paperChaseQuestionSchool {
          answer_a
          answer_b
          answer_c
          question
          right_answer
        }
        paperChaseQuestionVisitor {
          answer_a
          answer_b
          answer_c
          question
          right_answer
        }
      }
    }
  }
`;

PaperChaseStartPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.paperChaseData.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default PaperChaseStartPage;
