import React from "react";

import PropTypes from "prop-types";

const PaperChaseProgressBar = ({ length, activeIndex }) => {
  const lines = [];
  const lineWidth = 30;
  const lineHeight = 2;
  const lineY = 30 / 2;
  const height = 30 * 1.5;

  const circles = Array.from({ length }, (_, i) => {
    let circleFill = "#767676";

    if (i === activeIndex) {
      circleFill = "white";
    }

    return (
      <svg
        key={i}
        x={(i * (30 + lineWidth)) + (30 / 2)}
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={15}
          cy={15}
          r={13}
          stroke={circleFill}
          strokeWidth="3"
        />
        {i === activeIndex && (
          <>
            <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="14.25" fill="white" />
            <rect x="10.5" y="10.5" width="9" height="9" rx="4.5" fill="black" />
            <rect x="0.75" y="0.75" width="28.5" height="28.5" rx="14.25" stroke={circleFill} strokeWidth="1.5" />
          </>
        )}
      </svg>
    );
  });

  for (let i = 0; i < length - 1; i += 1) {
    let lineFill = "#767676";

    if (i === activeIndex) {
      lineFill = "white";
    }
    lines.push(
      <rect
        key={i}
        x={(i * (30 + lineWidth)) + (30 + (30 / 2))}
        y={lineY}
        width={lineWidth}
        height={lineHeight}
        fill={lineFill}
      />,
    );
  }

  return (
    <div>
      <svg
        width={(length * (30 + lineWidth))}
        height={height}
        viewBox={`0 0 ${(length * (30 + lineWidth))} ${30}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {circles}
        {lines}
      </svg>
    </div>
  );
};

PaperChaseProgressBar.propTypes = {
  activeIndex: PropTypes.number,
  length: PropTypes.number.isRequired,
};

PaperChaseProgressBar.defaultProps = {
  activeIndex: 1,
};

export default PaperChaseProgressBar;
