import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setDifficulty } from "../../features/PaperChaseSlice";
import Button from "../Button/Button";
import ContentContainer from "../ContentContainer/ContentContainer";
import RadioButton from "../RadioButton/RadioButton";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";

const PaperChaseStart = ({ page, classNameTitle, onStart }) => {
  const difficulty = useSelector((state) => state.paperChaseSlice.difficulty);
  const dispatch = useDispatch();

  const onChange = (event) => {
    dispatch(setDifficulty(event.target.id));
  };

  return (
    <ContentContainer>
      {page.image && (
        <div className="grid grid-cols-1 mt-7 mx-20">
          <SanityImage
            {...page.image}
            width={330}
            className="w-full"
            options={{ __experimentalAspectRatio: true }}
          />
        </div>
      )}
      <h2 className={`"text-h1-mobile my-5 ${classNameTitle}"`}>{page.name}</h2>
      {page._rawDescription && (
        <BlockContent
          blocks={page._rawDescription}
        />
      )}
      <br />
      <br /> Wählen Sie nun bitte die gewünschte Schwierigkeit aus:
      <RadioButtonGroup className="grid grid-cols-1 my-5 space-y-2 text-body2-desktop">
        <RadioButton id="paperChaseQuestionKids" name="difficulty" label="Kinder" color="white" key="1" onChange={onChange} />
        <RadioButton id="paperChaseQuestionSchool" name="difficulty" label="Schulen" color="white" key="2" onChange={onChange} />
        <RadioButton id="paperChaseQuestionVisitor" name="difficulty" label="Besucher" color="white" key="3" onChange={onChange} />
        <RadioButton id="paperChaseQuestionPro" name="difficulty" label="Kenner" color="white" key="4" onChange={onChange} />
      </RadioButtonGroup>
      <Button
        title="Button"
        color="white"
        size="large"
        centerChildren
        className="mt-8 w-full"
        onClick={onStart}
        disabled={!difficulty}
      >
        Starten
      </Button>
    </ContentContainer>
  );
};

PaperChaseStart.propTypes = {
  classNameTitle: PropTypes.string,
  onStart: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  page: PropTypes.object.isRequired,
};

PaperChaseStart.defaultProps = {
  classNameTitle: "",
};

export default PaperChaseStart;
