import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { CheckIcon24, CloseIcon24 } from "../../icons";
import Button from "../Button/Button";
import ContentContainer from "../ContentContainer/ContentContainer";
import Sharing from "../Sharing/Sharing";

const PaperChaseResult = ({
  description, title, classNameTitle, stations,
}) => {
  const score = useSelector((state) => state.paperChaseSlice.score);

  function showStation(station, index) {
    const qrCodeFound = (station.foundQRCode) ? <CheckIcon24 className="mr-2" /> : <CloseIcon24 className="mr-2" />;
    const qrCodePoints = (station.foundQRCode) ? 10 : 0;
    const qrCodePointsTxtColor = (station.foundQRCode) ? "" : "text-grey";

    const quizSolved = (station.solvedQuiz) ? <CheckIcon24 className="mr-2" /> : <CloseIcon24 className="mr-2" />;
    const quizPoints = (station.solvedQuiz) ? 5 : 0;
    const quizPointsTxtColor = (station.solvedQuiz) ? "" : "text-grey";

    return (
      <div className="text-body2-mobile my-5">
        <p className="text-label-mobile mb-2">Station {index + 1}: {station.title}</p>
        <div className="grid col-span-12 col-span-12">
          <div className="col-start-1">
            {qrCodeFound}
            QR-Code
          </div>
          <div className={`col-start-8 justify-self-end ${qrCodePointsTxtColor}`}>
            {qrCodePoints} Punkte
          </div>
        </div>
        <div className="grid col-span-12 col-span-12">
          <div className="col-start-1">
            {quizSolved}
            Quiz
          </div>
          <div className={`col-start-8 justify-self-end ${quizPointsTxtColor}`}>
            {quizPoints} Punkte
          </div>
        </div>
      </div>
    );
  }

  return (
    <ContentContainer>
      <p className="mt-9 text-lead-mobile text-grey">{title}</p>
      <h1 className={`"text-h1-mobile mt-1.5 mb-5 ${classNameTitle}"`}>{score} Punkte</h1>
      <div className="text-lead-mobile">{{ description } && (
        <BlockContent
          blocks={description}
        />
      )}
      </div>
      <br />
      <br />
      <p className="text-lead-mobile mb-5">Teilen Sie Ihren Erfolg mit Ihren Freunden oder der Community:</p>
      <Sharing shareTitle={title} />
      <hr className="border-grey my-16" />
      <div className="my-10">
        {stations.map((station, index) => (
          <div key={station}>
            {showStation(station, index)}
          </div>
        ))}
      </div>
      <Button title="Button" color="white" size="large" centerChildren className="mt-8 w-full">
        Beenden
        <CheckIcon24 className="text-white" />
      </Button>
    </ContentContainer>
  );
};

PaperChaseResult.propTypes = {
  classNameTitle: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.shape({ _rawDescription: PropTypes.string })),
  stations: PropTypes.arrayOf(PropTypes.shape({
    foundQRCode: PropTypes.bool.isRequired,
    solvedQuiz: PropTypes.bool.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
};

PaperChaseResult.defaultProps = {
  classNameTitle: "",
  description: [],
};

export default PaperChaseResult;
