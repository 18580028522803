import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const RadioButtonGroup = ({ children, className }) => (
  <div className={classNames("flex items-center gap-x-30 gap-y-6 flex-wrap", className)}>
    {children}
  </div>
);

RadioButtonGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  className: "",
};

export default RadioButtonGroup;
