import React, { useState } from "react";

import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { addUserAnswer, addUserPoints } from "../../features/PaperChaseSlice";
import { ArrowRightIcon24, QRCodeIcon24 } from "../../icons";
import Button from "../Button/Button";
import ContentContainer from "../ContentContainer/ContentContainer";
import PaperChaseCheck from "../PaperChaseCheck/PaperChaseCheck";
import RadioButton from "../RadioButton/RadioButton";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";

const PaperChaseStep = ({
  answer,
  stationNumber,
  question,
  title,
  classNameTitle,
  variant,
  hint,
  onNextStation,
  onSkip,
  onScan,
  onSendAnswer,
  image,
  rightAnswer,
}) => {
  let content = "";
  let achievablePoints;
  const quizPoints = 5;
  const qrCodePoints = 10;

  const [selectedUserAnswer, setSelectedUserAnswer] = useState("");
  const [finalUserAnswer, setFinalUserAnswer] = useState("");
  const answerIsCorrect = (finalUserAnswer === rightAnswer);

  if (variant === "step1") {
    achievablePoints = `QR-Code (${qrCodePoints}`;
    content = (
      <div>
        <div className="text-lead-mobile">{hint}</div>
        <br />
        <p>Finden Sie das Objekt und scannen Sie den QR-Code.</p>
        <p className="text-body1-mobile mt-8 text-grey">Sie können das Objekt nicht finden?</p>
        <Button title="Button" color="white" size="small" variant="secondary" className="mt-1.5 mb-44" onClick={onSkip}>
          Überspringen
        </Button>
        <Button title="Button" color="white" size="large" centerChildren className="mt-8 w-full" onClick={handleOnScan}>
          <QRCodeIcon24 />
          QR-Code Scannen
        </Button>
      </div>
    );
  }

  const dispatch = useDispatch();
  const currentStation = useSelector((state) => state.paperChaseSlice.currentStation);
  const score = useSelector((state) => state.paperChaseSlice.score);

  const handleAnswerSelected = (event) => {
    // Dispatch the addUserAnswer action with the current station and selected answer
    setSelectedUserAnswer(event.target.nextSibling.innerHTML);
  };

  const handleSubmitAnswer = () => {
    const selectedAnswerIsCorrect = (selectedUserAnswer === rightAnswer);

    if (selectedAnswerIsCorrect) {
      dispatch(addUserPoints(quizPoints));
    }

    setFinalUserAnswer(selectedUserAnswer);
    setSelectedUserAnswer("");

    dispatch(addUserAnswer({
      foundQRCode: true,
      index: currentStation,
      solvedQuiz: selectedAnswerIsCorrect,
    }));

    onSendAnswer();
  };

  function handleOnScan() {
    // ToDo: addUserPoints after finding the qrCode -> Change position
    dispatch(addUserPoints(qrCodePoints));
    onScan();
  }

  if (variant === "step2") {
    achievablePoints = `Quiz (${quizPoints}`;
    content = (
      <div>
        <div className="text-lead-mobile">{question}</div>
        <RadioButtonGroup className="grid grid-cols-1 my-5 space-y-2 text-body2-desktop">
          <RadioButton id="a" name="answer" label={answer[0]} color="white" key="1" onChange={handleAnswerSelected} />
          <RadioButton id="b" name="answer" label={answer[1]} color="white" key="2" onChange={handleAnswerSelected} />
          <RadioButton id="c" name="answer" label={answer[2]} color="white" key="3" onChange={handleAnswerSelected} />
        </RadioButtonGroup>
        <Button title="Button" color="white" size="large" centerChildren className="mt-8 w-full" disabled={!selectedUserAnswer} onClick={handleSubmitAnswer}>
          Antwort absenden
        </Button>
      </div>
    );
  }

  if (variant === "evaluateStep2") {
    achievablePoints = `Quiz (${quizPoints}`;
    content = (
      <>
        <div className="text-lead-mobile">{question}</div>
        <div className="mt-5">
          <PaperChaseCheck
            answerIsCorrect={answerIsCorrect}
            userAnswer={finalUserAnswer}
            answer={rightAnswer}
            quizPoints={quizPoints}
          />
          <Button title="Button" color="white" size="large" centerChildren className="mt-8 w-full" onClick={onNextStation}>
            Nächste Station
            <ArrowRightIcon24 />
          </Button>
        </div>
      </>
    );
  }

  if (variant === "lastStep") {
    achievablePoints = `Quiz (${quizPoints}`;
    content = (
      <>
        <div className="text-lead-mobile">{question}</div>
        <div className="mt-5">
          <PaperChaseCheck
            answerIsCorrect={answerIsCorrect}
            userAnswer={finalUserAnswer}
            answer={rightAnswer}
            quizPoints={quizPoints}
          />
          <Button title="Button" color="white" size="large" centerChildren className="mt-8 w-full" onClick={onNextStation}>
            Zum Ergebnis
            <ArrowRightIcon24 />
          </Button>
        </div>
      </>
    );
  }

  return (
    <ContentContainer>
      <p className="font-bold text-body1-mobile mt-6">Ihre gesammelten Punkte: {score}</p>
      <p className="mt-9 text-lead-mobile text-grey">Station {stationNumber} | {achievablePoints}P)</p>
      <h1 className={`"h1 mt-1.5 ${classNameTitle}"`}>{title}</h1>
      <div className="grid grid-cols-12 my-5">
        {image && image.length > 0 ? (
          <div className="justify-self-center col-start-3 col-end-11">
            <SanityImage
              {...image[0]}
              width={330}
              className="w-full"
              options={{ __experimentalAspectRatio: true }}
            />
          </div>
        ) : null}
      </div>
      {content}
    </ContentContainer>
  );
};

PaperChaseStep.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.string).isRequired,
  classNameTitle: PropTypes.string,
  hint: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({
      url: PropTypes.string,
    }),
  })),
  onNextStation: PropTypes.func.isRequired,
  onScan: PropTypes.func.isRequired,
  onSendAnswer: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  question: PropTypes.node.isRequired,
  rightAnswer: PropTypes.string.isRequired,
  stationNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["step1", "step2", "evaluateStep2", "lastStep"]),
};

PaperChaseStep.defaultProps = {
  classNameTitle: "",
  image: null,
  variant: "step1",
};

export default PaperChaseStep;
