import React, { useState } from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import Layout from "../../components/MainLayout/MainLayout";
import PaperChaseLeaveIntent from "../../components/PaperChaseLeaveIntent/PaperChaseLeaveIntent";
import { CloseIcon24 } from "../../icons";

const PaperChaseLayout = (props) => {
  const {
    children,
    title,
    variantButton,
    variantStyle,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  function showButton() {
    if (variantButton === "") {
      return "";
    }

    return <> {variantButton} <CloseIcon24 /> </>;
  }

  return (
    <div className="pb-6 bg-black">
      <Layout
        header={(
          <div className="grid grid-cols-2 items-center mb-16">
            <div className="text-body1-highlight-mobile grid-cols-1 ml-5 mt-7">
              {title}
            </div>
            <Button
              title="Button"
              color="white"
              size="small"
              centerChildren
              className="mt-7 mr-5 justify-self-end"
              variant="secondary"
              onClick={() => (variantButton === "Abbrechen" ? navigate("/schnitzeljagd") : setOpenModal(true))}
            >
              {showButton()}
            </Button>
          </div>
        )}
        footer={(
          <div />
        )}
        variant={variantStyle}
      >
        {children}
      </Layout>
      {/* ToDo: Scrollen deaktivieren, solange das Modal geöffnet ist */}
      <PaperChaseLeaveIntent open={openModal} onClose={() => setOpenModal(false)} description="Fortschritt und Punktestand gehen dabei verloren." title="möchten sie die schnitzeljagd wirklich beenden?" />
    </div>
  );
};

PaperChaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  variantButton: PropTypes.oneOf(["Abbrechen", "Beenden", ""]),
  variantStyle: PropTypes.oneOf(["primary", "secondary"]),
};

PaperChaseLayout.defaultProps = {
  variantButton: "Abbrechen",
  variantStyle: "secondary",
};

export default PaperChaseLayout;
