import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const RadioButton = (props) => {
  const {
    color,
    id,
    label,
    name,
    onChange,
  } = props;

  const styles = {
    black: {
      label: "text-black before:bg-[url('/icons/18/radiobutton-black.svg')] peer-checked:before:bg-[url('/icons/18/radiobutton-black-checked.svg')]",
    },
    white: {
      label: "text-white before:bg-[url('/icons/18/radiobutton-white.svg')] peer-checked:before:bg-[url('/icons/18/radiobutton-white-checked.svg')]",
    },
  };

  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        className="hidden peer"
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className={
          classNames(
            "inline-flex place-items-center text-body2 cursor-pointer",
            "before:w-18 before:h-18 before:inline-flex before:bg-center before:bg-contain before:mr-12",
            styles[color].label,
          )
        }
      >
        {label}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  color: PropTypes.oneOf(["black", "white"]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  color: "black",
};

export default RadioButton;
