import React from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";

import Button from "../Button/Button";
import ContentContainer from "../ContentContainer/ContentContainer";

const PaperChaseLeaveIntent = ({
  description, onClose, open, title,
}) => {
  if (!open) return null;

  return (
    <>
      <div className="bg-black/75 bottom-0 left-0 fixed right-0 top-0 z-2" onClick={onClose} role="presentation" />
      <div
        className="flex-none flex-col p-6 items-start absolute inset-0 w-11/12 h-min m-auto bg-white order-none grow-0 z-3"
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContentContainer>
          <h1 className="text-h4-mobile mt-6 mb-1.5">{title}</h1>
          <p className="text-body1-mobile mb-8">{description}</p>
          <Button title="Button" color="black" size="small" centerChildren className="mt-8 w-full" onClick={() => navigate("/schnitzeljagd")}>
            Schnitzeljagd Beenden
          </Button>
          <Button title="Button" color="black" size="small" variant="secondary" centerChildren className="mt-5 w-full mb-6" onClick={onClose}>
            Abbrechen
          </Button>
        </ContentContainer>
      </div>
    </>
  );
};

PaperChaseLeaveIntent.propTypes = {
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

PaperChaseLeaveIntent.defaultProps = {
};

export default PaperChaseLeaveIntent;
