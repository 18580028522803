import React from "react";

import PropTypes from "prop-types";

import { CheckIcon24, CloseIcon24 } from "../../icons";
import ContentContainer from "../ContentContainer/ContentContainer";

const PaperChaseCheck = ({
  answer,
  answerIsCorrect,
  quizPoints,
  userAnswer,

}) => {
  const icons = {
    checkMark:
  <CheckIcon24 />,
    cross:
  <CloseIcon24 />,
  };

  const headlines = {
    correctAnswer: "Richtige Antwort",
    falseAnswer: "Falsche Antwort",
  };

  const paragraphs = {
    showPoints: `+ ${quizPoints} Punkte`,
    showRightAnswer: `Richtig ist: [ ${answer} ]`,
  };

  let icon = icons.checkMark;
  let h2 = headlines.correctAnswer;
  let p = paragraphs.showPoints;

  if (!answerIsCorrect) {
    icon = icons.cross;
    h2 = headlines.falseAnswer;
    p = paragraphs.showRightAnswer;
  }

  return (
    <ContentContainer>
      <div className="grid justify-items-center border-2 gap-4">
        <div className="mt-5">{icon}</div>
        <h2 className="text-h5-mobile">{h2}</h2>
        <p className="text-body1-highlight-mobile mb-5">{p}</p>
      </div>
      <p className="text-body1-highlight-mobile my-5">
        <span className="text-grey">Ihre Antwort: </span>
        <span>[{userAnswer}]</span>
      </p>
    </ContentContainer>
  );
};

PaperChaseCheck.propTypes = {
  answer: PropTypes.string.isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  quizPoints: PropTypes.number.isRequired,
  userAnswer: PropTypes.string.isRequired,
};

PaperChaseCheck.defaultProps = {
};

export default PaperChaseCheck;
